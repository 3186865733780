import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming Soon!</h1>
        <p>
          Hello! My name is Keith Larson and I am running for Alderman at-large in Aurora, IL in the upcoming election on April 1, 2025.
          I am still working on this website, but to quickly introduce myself, I live about a mile southeast of downtown and I believe that
          Aurora needs to be investing money in things that actually benefit the community rather than giving tax breaks to corporations who
          donate to city council members. I don't take any donations from corporations or PACs.
        </p>
      </header>
    </div>
  );
}

export default App;
